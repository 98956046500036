<template>
  <v-main>
    <v-header
      class="article"
      :headerImage="article[articleId].image"
      :displayOverlay="true"
      :overlayCopy="article[articleId].title"
    />
    <container type="bg-dark article" id="start">
      <v-row>
        <v-col>
          <p class="mb-3">
            {{ article[articleId].date | moment("DD MMM YYYY") }}
          </p>
          <h2>
            <span v-for="(item, id) in article[articleId].title" :key="id"
              >{{ item.copy.toUpperCase() }}
            </span><br/>
            <span style="font-size: 18px;">{{ article[articleId].subTitle }}</span>
          </h2>
          <div class="full-article" v-html="article[articleId].fullArticle" />
        </v-col>
      </v-row>
      <hr />
      <h3 class="text-center mb-3">Other Related Articles</h3>
      <v-row>
        <v-col v-for="(item, id) in article" :cols="12" :md="4" :key="id">
          <v-card
            tile
            dark
            elevation="0"
            class="bg-transparent white--text mb-3 article"
            :to="{ name: 'article', params: { id: id, date: item.date } }"
          >
            <v-img
              :src="item.image"
              aspect-ratio="1"
              gradient="to bottom, rgba(0,0,0,.5), rgba(0,0,0,.5)"
              class="white--text align-center"
            >
              <v-card-text class="pb-0 text-center">{{
                item.date | moment("DD MMM YYYY")
              }}</v-card-text>
              <v-card-title class="font-weight-bold text-center justify-center">
                <span v-for="(item, id) in item.title" :key="id"
                  >{{ item.copy.toUpperCase() }}
                </span>
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </container>
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import { articles } from "@/data/articles.js";

export default {
  name: "Article",
  components: {
    vHeader,
  },
  data() {
    return {
      article: articles,
    };
  },
  computed: {
    articleId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style lang="scss">
.article h2 {
  margin-bottom: 1rem;
}
.article p {
  margin-bottom: 2rem !important;
}

.full-article a {
  text-decoration:underline!important;
}

.gallery-container {
  position: relative;
  display: inline-block;
}

.gallery {
  margin: 5px;
  border: 1px solid #4d4d4d;
  float: left;
  width: 260px;
}

.gallery:hover {
  border: 1px solid #777;
}

.gallery img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.desc {
  padding: 5px;
  text-align: center;
}
</style>
